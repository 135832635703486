import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import useMedia from 'use-media';

import Layout from '../layout/main';
import SEOComponent from '../components/seo';
import Hero4 from '../components/hero4';
import CTAComponent from '../components/cta';
import ProductsHighlight from '../components/productsHighlight';

const Container = styled.div`
    display: block;
    background-size: 100%;
    background: #fafafa;
    padding-top: 54px;
    padding-bottom: 80px;
    @media screen and (max-width: 767px) {
        padding-bottom: 90px;
    }
`;
const DesktopContainer = styled.div`
    margin: 0;
    display: flex;

    margin-left: 64px;
    margin-right: 64px;
    width: calc(100% - 128px);

    color: white;
    text-align: center;
    flex-direction: ${props =>
        props.direction === 'right' ? 'row' : 'row-reverse'};
    justify-content: space-between;
    @media screen and (max-width: 767px) {
        display: none;
    }
`;
const MobileContainer = styled.div`
    display: none;
    @media screen and (max-width: 767px) {
        display: flex;
        margin-left: 24px;
        margin-right: 24px;
        width: calc(100% - 48px);
        flex-direction: column;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    z-index: 0;
    margin-top: 0;
    @media screen and (max-width: 768px) {
        margin-top: 32px;
        width: 100%;
    }
`;
const Heading = styled.div`
    font-family: 'Renogare-Regular';
    font-size: 36px;
    text-align: left;
    color: #19171a;
    line-height: 40px;
    letter-spacing: -1.5px;
    user-select: none;
    @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.92px;
        text-align: left;
    }
`;

const Desc = styled.div`
    margin-top: 34px;
    font-size: 17px;
    font-family: 'Neuzeit Grotesk';
    text-align: left;
    letter-spacing: normal;
    line-height: 24px;
    color: rgba(25, 23, 26, 0.9);
    user-select: none;
    white-space: pre-wrap;
    @media screen and (max-width: 768px) {
        font-size: 17px;
        font-family: 'Neuzeit Grotesk';
        text-align: left;
        letter-spacing: normal;
        line-height: 24px;
    }
`;
const ImgContainer = styled.div`
    width: 40%;
    display: flex;
    justify-content: flex-end;

    & > * {
    }
    @media screen and (max-width: 767px) {
        width: 100%;
        padding: 0;
        margin-top: 24px;
        & > * {
        }
    }
`;

export default function SmartOTPPage({ data }) {
    const {
        hero,
        smart_otp_intro,
        highlights,
        book_now,
        seo_content,
    } = data.butterPage;
    const isWide = useMedia({ minWidth: 767 });

    return (
        <Layout menu="FOR PUBLISHERS">
            <SEOComponent
                title="SmartOTP"
                image={seo_content.sharing_image}
                description={seo_content.seo_description}
            />
            <Hero4 {...hero[0]} type={1}></Hero4>
            <Container>
                {isWide ? (
                    <DesktopContainer>
                        <ImgContainer>
                            <img
                                src={smart_otp_intro.image}
                                width="100%"
                                height="100%"
                                alt={smart_otp_intro.title}
                                style={{ objectFit: 'contain' }}
                            ></img>
                        </ImgContainer>
                        <Content>
                            <div>
                                <Heading>{smart_otp_intro.title}</Heading>
                                <Desc>
                                    {smart_otp_intro.description}
                                </Desc>
                            </div>
                        </Content>
                    </DesktopContainer>
                ) : (
                    <MobileContainer>
                        <Heading>{smart_otp_intro.title}</Heading>
                        <ImgContainer>
                            <img
                                src={smart_otp_intro.image}
                                width="100%"
                                height="100%"
                                alt={smart_otp_intro.title}
                            ></img>
                        </ImgContainer>
                        <Desc>{smart_otp_intro.description}</Desc>
                    </MobileContainer>
                )}
            </Container>

            <ProductsHighlight {...highlights[0]} />

            <CTAComponent {...book_now[0]} />
        </Layout>
    );
}

export const smartOTPPageQuery = graphql`
    query {
        butterPage(slug: { eq: "smart-otp" }) {
            seo_content {
                seo_description
                sharing_image
            }
            hero {
                image
                description
                desktop_image
                mobile_image
                mask_image
                buttons {
                    type
                    title
                    theme
                    url
                }
            }
            smart_otp_intro {
                title
                description
                image
            }
            highlights {
                title
                features {
                    title
                    description
                    image
                }
            }
            book_now {
                button_title
                title
            }
        }
    }
`;
